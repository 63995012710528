export const PRODUCT_CONSTANTS = {
  STATUS: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    PAUSED: 'Paused'
  },
  STATUS_LABEL: {
    ENABLED: 'Activo',
    DISABLED: 'Deshabilitado',
    PAUSED: 'Pausado'
  },
  PRICE_BY: {
    HOUR: 'hora',
    DAY: 'dia'
  }
}
