export const GOOGLE_MAPS_GEOCODER = {
  STATUS : {
    ERROR : 'ERROR',
    INVALID_REQUEST: 'INVALID_REQUEST',
    OK: 'OK',
    OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT',
    REQUEST_DENIED: 'REQUEST_DENIED',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    ZERO_RESULTS: 'ZERO_RESULTS'
  },
  STATUS_DESCRIPTION : {
    ERROR : 'There was a problem contacting the Google servers.',
    INVALID_REQUEST: 'This GeocoderRequest was invalid.',
    OK: 'The response contains a valid GeocoderResponse.',
    OVER_QUERY_LIMIT:'The webpage has gone over the requests limit in too short a period of time.',
    REQUEST_DENIED: 'The webpage is not allowed to use the geocoder.',
    UNKNOWN_ERROR: 'A geocoding request could not be processed due to a server error. The request may succeed if you try again.',
    ZERO_RESULTS: 'No result was found for this GeocoderRequest.'
  }
};
