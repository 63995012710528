export const CONTACT_SUBJECT = {
  OPTIONS: [
    { key: 'BUG', value : 'Quiero reportar un error  técnico.' },
    { key: 'ACCOUNT_BUG', value : 'Tengo un problema con mi cuenta.'},
    { key: 'RENT_BUG', value : 'Tengo otro problema.'},
    { key: 'FRAUD_DENUNCE', value : 'Quiero reportar un fraude.'},
    { key: 'SUGGESTION', value : 'Quiero hacer una sugerencia.'},
    { key: 'OTHER', value : 'Otro.'}
  ]
};
