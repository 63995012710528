export const RENTALS_CONSTANTS = {
  STATUS: {
    SENT: 'SENT',
    RECEIVED: 'RECEIVED',
    COMMIT_FOR_OWNER: 'COMMIT_FOR_OWNER',
    COMMIT_FOR_RENTER: 'COMMIT_FOR_RENTER',
    CANCEL_FOR_OWNER: 'CANCEL_FOR_RENTER',
    CANCEL_FOR_RENTER: 'CANCEL_FOR_RENTER',
    CLOSED: 'CLOSED',
    EXPIRED: 'EXPIRED'
  },
  FROM: {
    GUEST: 'GUEST',
    USER: 'USER',
    COMPANY_OWNER: 'COMPANY_OWNER'
  },
  TO: {
    USER: 'USER',
    COMPANY: 'COMPANY',
  },
  STATUS_LABEL: {
    SENT: 'Enviada',
    RECEIVED: 'Recibida',
    COMMIT_FOR_OWNER: 'Aceptada',
    COMMIT_FOR_RENTER: 'Aceptada',
    CANCEL_FOR_OWNER: 'Rechazada',
    CANCEL_FOR_RENTER: 'Rechazada',
    CLOSED: 'Cerrada',
    EXPIRED: 'Vencida'
  },
  TEXT: {
    OWNER: {
      CREATED: {
        TITLE: 'Solicitud creada',
        SUBTITLE: 'Solicitud creada',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      Confirmed: {
        TITLE: 'Solicitud confirmada',
        SUBTITLE: 'Solicitud confirmada',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      CLOSED: {
        TITLE: 'Solicitud cerrada',
        SUBTITLE: 'Esta solicitud a sido cerrada por falta de respuesta o por el administrador',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      WAITING_FOR_RESPONSE: {
        TITLE: 'Solicitud de alquiler',
        SUBTITLE: 'Responde a esta solicutd',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      COMMIT_FOR_OWNER: {
        TITLE: 'Solicitud Confirmada',
        SUBTITLE: 'Confirmaste esta solicitud',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      COMMIT_FOR_RENTER: {
        TITLE: '333',
        SUBTITLE: '4444',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      CANCEL_FOR_OWNER: {
        TITLE: '',
        SUBTITLE: '',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      CANCEL_FOR_RENTER: {
        TITLE: '',
        SUBTITLE: '',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      }
    },
    RENTER: {
      CREATED: {
        TITLE: 'Solicitud creada',
        SUBTITLE: 'Solicitud creada',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      Confirmed: {
        TITLE: 'Solicitud confirmada',
        SUBTITLE: 'Esta solicitud esta confirmada',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      CLOSED: {
        TITLE: 'Solicitud cerrada',
        SUBTITLE: 'Esta solicitud a sido cerrada por falta de respuesta o por el administrador',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      WAITING_FOR_RESPONSE: {
        TITLE: 'Solicitud enviada',
        SUBTITLE: 'Tu solicitud fue enviada con exito !!',
        PARAG: 'Hemos enviado tu solicitud, cuando el propietario la Confirme, podras retirar tu producto en el domicilio especificado. El propietario puede Cancelar tu solicitud. En cualquiera de los dos casos, te enviaremos un SMS.'
      },
      COMMIT_FOR_OWNER: {
        TITLE: 'Solicitud Confirmada',
        SUBTITLE: 'Tu solicitud fue aceptada !!',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      COMMIT_FOR_RENTER: {
        TITLE: 'Solicitud Confirmada',
        SUBTITLE: 'Tu solicitud fue confirmada !!',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      CANCEL_FOR_OWNER: {
        TITLE: 'Solicitud Cancelada',
        SUBTITLE: '',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      },
      CANCEL_FOR_RENTER: {
        TITLE: 'Solicitud Cancelada',
        SUBTITLE: 'Cancelaste esta solicitud',
        PARAG: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui'
      }
    }
  }
};
