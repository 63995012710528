export const IMG_SIZE = {
  'extra_large' : {
        width: 1280,
        height: 1024,
        name: 'extra_large'
    },
  'large' : {
      width: 1024,
      height: 768,
      name: 'large'
  },
  'extra_medium' : {
      width: 620,
      height: 580,
      name: 'extra_medium'
  },
  'medium' : {
      width: 580,
      height: 435,
      name: 'medium'
  },
  'small' : {
      width: 280,
      height: 210,
      name: 'small'
  },
  'thumbnail': {
      width: 100,
      height: 100,
      name: 'thumbnail'
  }
};
