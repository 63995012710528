export const APP_CONSTANTS = {
  SITE_NAME: 'Alquilar Online',
  LOGO: 'https://static-prod-alquilaronline.s3.amazonaws.com/portal/logos/alquilar-online-375x96.png',
  THEME: 'aol',
  SEARCH: 'Buscá un artículo para alquilar',
  WELCOME: 'Bienvenido al portal de alquiler mas grande de Argentina!',
  PUBLISH_BTN_TITLE: 'Publicá tu artículo',
  HOME_SLIDES: [
    { title: 'The biggest sale', subtitle: 'Ganá dinero publicando lo que no usás y llegá a miles de personas.', image: 'assets/images/carousel/banner1.jpg' },
    { title: 'Summer collection', subtitle: 'Alquilá lo que necesitás, por el tiempo que lo vas a usar y no gastes de más.', image: 'assets/images/carousel/banner2.jpg' },
    { title: 'The biggest sale', subtitle: 'Buscá y encontrá el artículo que necesitás cerca tuyo. Es rápido y sencillo.', image: 'assets/images/carousel/banner3.jpg' },
    { title: 'Summer collection', subtitle: 'Contribuí al medio ambiente, comprando menos y usando más.', image: 'assets/images/carousel/banner4.jpg' },
    { title: 'The biggest sale', subtitle: 'Special for today', image: 'assets/images/carousel/banner5.jpg' }
  ],
  HOME_BANNERS: {
    MOBILE: [
      { image: 'https://static-prod-alquilaronline.s3.amazonaws.com/portal/landing/banner-MOBILE-1.png' },
      { image: 'https://static-prod-alquilaronline.s3.amazonaws.com/portal/landing/banner-MOBILE-2.png' },
      { image: 'https://static-prod-alquilaronline.s3.amazonaws.com/portal/landing/banner-MOBILE-3.png' }
    ],
    WEB: [
      { image: 'https://static-prod-alquilaronline.s3.amazonaws.com/portal/landing/banner-web-1.png' },
      { image: 'https://static-prod-alquilaronline.s3.amazonaws.com/portal/landing/banner-web-2.png' },
      { image: 'https://static-prod-alquilaronline.s3.amazonaws.com/portal/landing/banner-web-3.png' }
    ]
  },
  HOME_HOW_IT_WORKS: {
    SECTION_1: 'Ganá dinero publicando lo que no usás y llegá a miles de personas.',
    SECTION_2: 'Alquilá lo que necesitás, por el tiempo que lo vas a usar y no gastes de más.',
    SECTION_3: 'Buscá y encontrá el artículo que necesitás cerca tuyo. Es rápido y sencillo.',
    SECTION_4: 'Contribuí al medio ambiente, comprando menos y usando más.',
  },
  HOME_PRODUCTS: [
    { title: 'Destacados', url : '' },
    { title: 'Ultimos publicados', url : '' },
    { title: 'Lo mas alquilado', url : '' },
    { title: 'Lo mas pedido', url : '' }
  ],
  INFO: {
    SECTION_1: {
      TITLE : 'Buscá en empresas.',
      CONTENT : 'Navegá a través de nuestro completo catálogo de empresas. Consultá, asesorate y encontrá el artículo en alquiler que estás buscando.'
    },
    SECTION_2: {
      TITLE : 'Anunciate gratis.',
      CONTENT : 'Publicá lo que necesitás. Creá un anuncio en nuestra sección de alquileres pedidos. Si alguien lo tiene, nosotros te avisamos. Así de fácil.'
    }
  },
  NEWSLETTER: {
    TITLE: 'Suscríbete a nuestro boletín',
    SUBTITLE: 'Manténte actualizado con nuestras últimas novedades y productos.',
    PLACEHOLDER: 'Tu dirección de email ..',
    BTN_TXT: 'Suscribirme'
  }
};
