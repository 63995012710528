
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { ROUTES_NAMES } from 'src/app/constants';
import { User } from 'src/app/models';
import { Settings, AppSettings } from 'src/app/app.settings';
import { AuthenticationService } from 'src/app/services';
import { SidenavMenuService } from 'src/app/theme/components/sidenav-menu/sidenav-menu.service';
import { APP_CONSTANTS } from 'src/app/app.constants';
import { PROFILES } from 'src/app/constants/profiles.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ SidenavMenuService ]
})
export class HeaderComponent implements OnInit {
  user: User;
  user$: Subscription;
  ROUTES_NAMES = ROUTES_NAMES;
  APP_CONSTANTS = APP_CONSTANTS;
  PROFILES = PROFILES;
  public settings: Settings;
  public portal = environment.PORTAL;
  public api = environment.API_URL;
  searchForm: FormGroup;

  constructor(
    public appSettings: AppSettings,
    public sidenavMenuService: SidenavMenuService,
    public authenticationService: AuthenticationService,
    public formBuilder: FormBuilder,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
    if (this.authenticationService.currentUserValue) {
      this.user = this.authenticationService.currentUserValue;
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    console.log('HeaderComponent');
    this.searchForm = this.formBuilder.group({
      user_search: []
    });
  }

  public search(user_search){
    this.router.navigate(['/productos'], { queryParams: { 'user_search': user_search }});
  }

  public closeSubMenus(){}

  public logout(): void {
    this.authenticationService.logout();
  }

}
