import { Component, OnInit, Input } from '@angular/core';
import { IProduct } from 'src/app/models';
import { IMG_SIZE } from 'src/app/constants';

@Component({
  selector: 'app-card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.component.scss']
})
export class CardProductComponent implements OnInit {

  @Input() title = 'Producto';
  @Input() product: IProduct;
  IMG_SIZE = IMG_SIZE;

  constructor() { }

  ngOnInit() {
  }

}
