import { Component, ViewEncapsulation, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validator, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { CompanyService } from 'src/app/services/company.service';
import { Data, AppService } from 'src/app/app.service';
import { ICompany, User, IPlan } from 'src/app/models';
import { VIEW_STATES } from './company-dialog.constants';
import { ROUTES_NAMES } from 'src/app/constants/route-names';
import { COMPANY_CONSTANTS } from '../../constants/company.constants';
import { PlanService } from 'src/app/services';

@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyDialogComponent implements OnInit {
  public config = { };
  form: FormGroup;
  user: User = null;
  viewState = VIEW_STATES.LOADING_COMPANY;
  VIEW_STATES = VIEW_STATES;
  ROUTES_NAMES = ROUTES_NAMES;
  defaultCompanyLogo = COMPANY_CONSTANTS.DEAFULT_LOGO_URL;
  plans: Array<IPlan> = [];
  constructor(public appService: AppService,
              private companyService: CompanyService,
              private plansService: PlanService,
              public formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<CompanyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public company: ICompany) { }

   async ngOnInit() {
    console.log(this.company);
    this.plansService.getAll().subscribe((plans) => {
      console.log(plans);
      this.plans = plans;
      this.buildForm(this.company);
    },
    (error) => {
       console.error('error: ' + error);
    });
   }

   buildForm(company: ICompany) {
    // console.log(this.plans);
    // console.log(this.plans.find(p => p.code === company.plan.code));
    this.form = this.formBuilder.group({
      name: [company.name, Validators.compose([Validators.required, Validators.maxLength(50)])],
      email: [company.email, Validators.compose([Validators.required, Validators.email, Validators.minLength(4), Validators.maxLength(50)])],
      description: [company.description],
      address: [company.address],
      phone: [company.phone],
      plan: [this.getPlan(company)],
      web: [company.web],
      owner: [company.owner],
      logo: [company.logo],
      url: [company.url],
      url_short: [company.url_short],
    });
    this.viewState = VIEW_STATES.SHOW_FORM;
  }

  getPlan(company) {
    let plan = null;
    if (company.plan ) {
      plan = this.plans.find(p => p.code === company.plan.code);
    }
    return plan;
  }

  onFormSubmit(): void {
    const data = Object.assign({}, this.company, this.form.value);
    console.log(data);
    if (this.form.valid) {
      this.companyService.updateCompany(data).subscribe((response) => {
        console.log(response);
        this.dialogRef.close(response);
      },
      (error) => {
        this.viewState = VIEW_STATES.UPDATE_ERROR;
        console.log(error);
      });
    } else {
      alert("invalid form");
    }
  }

  getPlans() {
    return this.plansService.getAll().subscribe((plans) => {
      console.log(plans);
      this.plans = plans;
    },
    (error) => {
       console.error('error: ' + error);
    });
  }

  public close(): void {
    this.dialogRef.close();
  }
}
