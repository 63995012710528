import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { UserMenuComponent } from './header/user-menu/user-menu.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    HeaderComponent,
    SideNavComponent,
    UserMenuComponent,
    BreadcrumbComponent
  ],
  exports: [
    HeaderComponent,
    SideNavComponent,
    UserMenuComponent,
    BreadcrumbComponent
  ]
})
export class LayoutModule { }
