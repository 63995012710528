import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SwiperConfigInterface, SwiperPaginationInterface, SwiperDirective } from 'ngx-swiper-wrapper';

import { FileObject, FileObjectStatus } from 'src/app/services/types';
import { S3UploadService } from 'src/app/services/s3-upload.service';
import { toSlug } from '../util';

@Component({
  selector: 'app-images-carousel',
  templateUrl: './images-carousel.component.html',
  styleUrls: ['./images-carousel.component.scss']
})
export class ImagesCarouselComponent implements AfterViewInit {
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;
  // tslint:disable-next-line: no-input-rename
  @Input('files') files: Array<FileObject> = [];
  @Output() imageUploaded = new EventEmitter<any>();
  @Output() updateMainImage = new EventEmitter<any>();
  @Output() imageRemoved = new EventEmitter<any>();
  eventsSubject: Subject<any> = new Subject<any>();

  config: SwiperConfigInterface = {};
  pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  currentIndex = 0;

  constructor(private s3UploadService: S3UploadService) {
    
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      speed: 500,
      effect: 'slide'
    };
  }

  public setFirstImagetoMain(url) {
    console.log(url);
    this.eventsSubject.next({
      url: url,
      action: 'setImagetoMain',
      value: true
    });
  }

  

  clearImagePrincipalToForm() {
    this.updateMainImage.emit('');
  }

  onIndexChange(index: number) {
    this.currentIndex = index;
  }

  onImageUploaded(url: any) {
    this.imageUploaded.emit(url);
  }

  onUpdateMain(url) {
    this.eventsSubject.next({
      action: 'clearMainImage'
    });
    this.eventsSubject.next({
      action: 'setImagetoMain',
      url: url
    });
    this.updateMainImage.emit(url);
  }

  onDeleteImage(imgUrl) {
    this.imageRemoved.emit(imgUrl);
    this.directiveRef.swiper().removeSlide(this.currentIndex);
  }

}
