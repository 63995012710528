import { Pipe, PipeTransform } from '@angular/core';
import { DELIVERY_OPTIONS} from '../../models/delivery-options.enum';

@Pipe({name: 'deliveryOptionValue'})
export class DeliveryOptionPipe implements PipeTransform {
  transform(key: string): string {
  let value = DELIVERY_OPTIONS[key];
    return value;
  }
}
