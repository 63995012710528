export const DELIVERY_OPTIONS_CONSTANTS = {
  STATUS: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled'
  },
  CODES: {
    IT_IS_COLLECTED_BY_MY_LOCATION : 'IT_IS_COLLECTED_BY_MY_LOCATION',
    DELIVERY_AT_HOME : 'DELIVERY_AT_HOME',
    AGREED_IN_ONE_PLACE: 'AGREED_IN_ONE_PLACE'
  }
};
