import { Injectable } from '@angular/core';
import { IMG_SIZE } from '../constants/images-size';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ImagesService {

  getUrl(url: string, imgSizeItem: any, extension: string = 'jpeg') {
    let newImgUrl = '';
    if(url.length === 0) {
      return `${environment.AWS_CLOUDFRONT}/_default/original/no_photo.jpg`;
    }
    const size = IMG_SIZE[imgSizeItem.name].name;
    const parentFolderName = url.split('/').slice(-2)[0]; // parent directory name
    const pathWhithoutExt = url.replace(/\.[^/.]+$/, ''); // remove extension
    newImgUrl = pathWhithoutExt.replace(parentFolderName, size) + '.' + extension;
    return newImgUrl;
  }

}
