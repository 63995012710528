import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterBrandsPipe } from './filter-brands.pipe';
import { BrandSearchPipe } from './brand-search.pipe';
import { DeliveryOptionPipe } from './delivery-option';
import { FileSizePipe } from './file-size.pipe';
import { PhonePipe } from './phone.pipe';
import { ImgSizePipe } from './img-size.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FilterByIdPipe,
        FilterBrandsPipe,
        BrandSearchPipe,
        DeliveryOptionPipe,
        FileSizePipe,
        PhonePipe,
        ImgSizePipe
    ],
    exports: [
        FilterByIdPipe,
        FilterBrandsPipe,
        BrandSearchPipe,
        DeliveryOptionPipe,
        FileSizePipe,
        PhonePipe,
        ImgSizePipe
    ]
})
export class PipesModule { }
