import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

import { environment } from '../../environments/environment';
import { API } from '../constants/api';
import { User } from '../models/user';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient,  @Inject(PLATFORM_ID) private platformId: object) {
    const user = (isPlatformBrowser(this.platformId)) ? JSON.parse(localStorage.getItem('currentUser')) : null;
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUser.subscribe(data => {
      // console.log(data);
    });
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.API_URL}${API.AUTHENTICATE}`, {
        username,
        password
      })
      .pipe(
        map(resp => {
          const user: User = jwt_decode(resp.token);
          user.token = resp.token;
          if (isPlatformBrowser(this.platformId)) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
          }
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
    }
    this.currentUserSubject.next(null);
  }
}
