import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { API } from '../constants/api';

@Injectable({ providedIn: 'root' })
export class PlanService {
  private url;

  constructor(public http: HttpClient) {
    this.url = `${environment.API_URL}${API.PLANS}`;
  }

  public getAll(): Observable<any[]> {
     return this.http.get<Array<any>>(this.url);
  }
  
}