export enum ContainerEvents {
  Upload,
  Cancel,
  Delete
}

export enum FileObjectStatus {
  NotStarted,
  Uploading,
  Uploaded,
  Canceled,
  Deleted,
  Failed
}

export class FileObject {
  status = FileObjectStatus.NotStarted;
  file: File;
  image: string;
  prefix: string;

  constructor(file: File) {
    this.file = file;
  }

}
