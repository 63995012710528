import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-user-reputation',
  templateUrl: './card-user-reputation.component.html',
  styleUrls: ['./card-user-reputation.component.scss']
})
export class CardUserReputationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
