import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { first } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CryptoService } from 'src/app/services/crypto.service';

@Component({
  selector: 'app-ghost-modal',
  templateUrl: './ghost-modal.component.html',
  styleUrls: ['./ghost-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GhostModalComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  target_email: string;
  target_name: string;
  user: User = null;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  invalidCredentials: boolean;
  constructor(public formBuilder: FormBuilder,
              private cryptoService: CryptoService,
              private spinner: NgxSpinnerService,
              public dialogRef: MatDialogRef<GhostModalComponent>,
              private authenticationService: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              @Inject(PLATFORM_ID) private platformId: object) {
                this.target_email = data.target_email;
                this.target_name = data.target_name;
                this.user = this.authenticationService.currentUserValue;
              }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [this.user.email, Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      target_email: [this.target_email],
      target_name: [this.target_name]
    });
  }

  /* Handle form errors in Angular 8 */
  public errorHandling = (control: string, error: string) => {
    return this.loginForm.controls[control].hasError(error);
  }

  onSubmit() {
    console.log(this.loginForm.value);
    if (this.loginForm.invalid) {
      return;
    }
    this.authenticationService
      .login(this.loginForm.get('email').value, this.loginForm.get('password').value)
      .pipe(first())
      .subscribe(
        data => {
          this.loginToPortal();
        },
        error => {
          console.log(error);
          this.spinner.hide();
          this.error = error;
          this.invalidCredentials = true;
          this.loading = false;
        }
    );
  }

  public close(): void {
    this.dialogRef.close();
  }

  loginToPortal() {
    const data = {
      email: this.loginForm.value.email.toLowerCase().trim(),
      password: this.loginForm.value.password.trim(),
      target: this.loginForm.value.target_email.toLowerCase().trim()
    };
    console.log(data);
    const userString = JSON.stringify(data);
    const encripted = this.cryptoService.encrypt(userString);
    console.log(userString);
    console.log(encripted);
    const externalUrl = environment.PORTAL + 'login?ghost=' + encodeURIComponent(encripted);
    window.open(externalUrl, '_blank');
    this.close();
  }

  ngOnDestroy() {

  }

}
