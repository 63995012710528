import { Component, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { ROUTES_NAMES } from 'src/app/constants';
import { User } from 'src/app/models';
import { Settings, AppSettings } from 'src/app/app.settings';
import { AuthenticationService } from 'src/app/services';
import { SidenavMenuService } from 'src/app/theme/components/sidenav-menu/sidenav-menu.service';
import { AppService } from 'src/app/app.service';
import { Category } from 'src/app/app.models';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  providers: [ SidenavMenuService ]
})
export class SideNavComponent implements OnInit {
  user:User;
  user$: Subscription;
  ROUTES_NAMES = ROUTES_NAMES;
  public settings: Settings;
  public categories: Category[];
  public category: Category;
  public sidenavMenuItems: Array<any>;
  @ViewChild('sidenav', { static: true }) sidenav: any;
 /*
  public settings: Settings;

  user_search: string;

  searchForm: FormGroup;
  APP_SITE = APP_SITE;
  user$: Subscription;
  toolbar = 'ANONIMO';
  public selectedVal;
  */
  constructor(
    public appSettings: AppSettings,
    public appService: AppService,
    public sidenavMenuService: SidenavMenuService,
    public authenticationService: AuthenticationService,
    public formBuilder: FormBuilder,
    private router: Router

  ) {
    this.settings = this.appSettings.settings;
    if (this.authenticationService.currentUserValue) {
      this.user = this.authenticationService.currentUserValue;
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    console.log('SideNavComponent');
    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
  }

  public getCategories(){
    this.appService.getCategories().subscribe(data => {
      this.categories = data;
      this.category = data[0];
    });
  }

  public changeCategory(event){
    if(event.target){
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
    }
    if(window.innerWidth < 960){
      this.stopClickPropagate(event);
    }
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public closeSubMenus(){
    if(window.innerWidth < 960){
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  logout(): void {
    this.authenticationService.logout();
  }

}
