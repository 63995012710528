import { Component, OnInit, Input } from '@angular/core';
import { ICustomer } from 'src/app/models/customer.interface';
import { AddressService } from 'src/app/services/address.service';
import { IAddress } from 'src/app/models';

@Component({
  selector: 'app-card-customer',
  templateUrl: './card-customer.component.html'
})
export class CustomerComponent implements OnInit {

  @Input() title = 'Cliente';
  @Input() customer: ICustomer;

  constructor(private addressService: AddressService) { }

  ngOnInit() {
  }

  getAddress(address: IAddress) {
    return this.addressService.toString(address);
  }

}
