import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { GravatarModule } from 'ngx-gravatar';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule,
         MatButtonModule,
         MatButtonToggleModule,
         MatCardModule,
         MatCheckboxModule,
         MatChipsModule,
         MatDatepickerModule,
         MatDialogModule,
         MatExpansionModule,
         MatGridListModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatMenuModule,
         MatNativeDateModule,
         MatPaginatorModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatRadioModule,
         MatRippleModule,
         MatSelectModule,
         MatSidenavModule,
         MatSliderModule,
         MatSlideToggleModule,
         MatSnackBarModule,
         MatSortModule,
         MatTableModule,
         MatTabsModule,
         MatToolbarModule,
         MatTooltipModule,
         MatStepperModule,
         MatPaginatorIntl} from '@angular/material';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

import { PipesModule } from '../theme/pipes/pipes.module';
import { RatingComponent } from './rating/rating.component';
import { ControlsComponent } from './controls/controls.component';
import { MainCarouselComponent } from './main-carousel/main-carousel.component';
import { BrandsCarouselComponent } from './brands-carousel/brands-carousel.component';
import { ProductsCarouselComponent } from './products-carousel/products-carousel.component';
import { ProductDialogComponent } from './products-carousel/product-dialog/product-dialog.component';
import { BannersComponent } from './banners/banners.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { MatPaginatorIntlCustom } from './paginator/mat-paginator';
import { ImagesCarouselComponent } from './images-carousel/images-carousel.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { EnviromentCardComponent } from './cards/enviroment-card/enviroment-card.component';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadImageModalComponent } from './upload-image-modal/upload-image-modal.component';
import { CardRentalComponent } from './card-rental/card-rental.component';
import { CardProductComponent } from './card-product/card-product.component';
import { CardUserComponent } from './card-user/card-user.component';
import { CardUserReputationComponent } from './card-user-reputation/card-user-reputation.component';
import { CardCompanyComponent } from './card-company/card-company.component';
import { CardRentalPricesComponent } from './card-rental-prices/card-rental-prices.component';
import { CardRentalEventsComponent } from './card-rental-events/card-rental-events.component';
import { CardRentalMessagesComponent } from './card-rental-messages/card-rental-messages.component';
import { SelectImageModalComponent } from './select-image-modal/select-image-modal.component';
import { S3UploadService } from '../services/s3-upload.service';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CardTrackingComponent } from './card-tracking/card-tracking.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { CustomerComponent } from './card-customer/card-customer.component';
import { GhostModalComponent } from './ghost-modal/ghost-modal.component';
import { CardJsonComponent } from './card-json/card-json.component';
import { CompanyDialogComponent } from './company-dialog/company-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SwiperModule,
    GravatarModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    NgxJsonViewerModule
  ],
  exports: [
    RouterModule,
    SwiperModule,
    NgxJsonViewerModule,
    GravatarModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    ProductDialogComponent,
    BannersComponent,
    CategoryListComponent,
    ImagesCarouselComponent,
    FileUploadComponent,
    AddressModalComponent,
    SelectImageModalComponent,
    UploadImageModalComponent,
    CardRentalComponent,
    CardProductComponent,
    CardUserComponent,
    CardUserReputationComponent,
    CardCompanyComponent,
    CardRentalPricesComponent,
    CardRentalEventsComponent,
    CardRentalMessagesComponent,
    CardTrackingComponent,
    ConfirmModalComponent,
    CustomerComponent,
    UserAvatarComponent,
    GhostModalComponent,
    CardJsonComponent,
    CompanyDialogComponent
  ],
  declarations: [
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    ProductDialogComponent,
    BannersComponent,
    CategoryListComponent,
    ImagesCarouselComponent,
    FileUploadComponent,
    EnviromentCardComponent,
    AddressModalComponent,
    SelectImageModalComponent,
    UploadImageModalComponent,
    CardRentalComponent,
    CardProductComponent,
    CardUserComponent,
    CustomerComponent,
    CardUserReputationComponent,
    CardCompanyComponent,
    CardRentalPricesComponent,
    CardRentalEventsComponent,
    CardRentalMessagesComponent,
    CardTrackingComponent,
    ConfirmModalComponent,
    UserAvatarComponent,
    GhostModalComponent,
    CardJsonComponent,
    CompanyDialogComponent
  ],
  entryComponents: [
    ProductDialogComponent,
    SelectImageModalComponent,
    FileUploadComponent,
    ConfirmModalComponent,
    GhostModalComponent,
    CompanyDialogComponent
  ],
  providers: [
    S3UploadService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom }
  ]
})
export class SharedModule { }
