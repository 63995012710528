// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enableLogger: false,
  APP_KEY: 'yz8j6l8VnR',
  PORTAL:  'https://test.alquilaronline.com/',
  STATIC_URL: 'https://d2soamdl0oivgp.cloudfront.net/',
  API_URL: 'https://test.alquilaronline.com/api',
  DATA_MOCK: '/assets/data/',
  AWS_USER : 'aol',
  AWS_ACCESS_KEY: 'AKIASZ46SR27ADBPG5NL',
  AWS_ACCESS_SECRET: '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',
  AWS_REGION: 'us-east-2',
  AWS_BUCKET: 'aol.product.assets.dev',
  AWS_CLOUDFRONT: 'https://d6x681jlhx03p.cloudfront.net',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCWbBajbUrFgKueGo3gYDHz0YjN8zTCtlo'
};
