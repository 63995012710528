import { Component, OnInit, Input } from '@angular/core';
import { IRental } from 'src/app/models';

@Component({
  selector: 'app-card-rental-prices',
  templateUrl: './card-rental-prices.component.html',
  styleUrls: ['./card-rental-prices.component.scss']
})
export class CardRentalPricesComponent implements OnInit {

  @Input() title = 'Dirección';
  @Input() rental: IRental;
  constructor() { }

  ngOnInit() {
  }

  addDeliveryCost() {
    console.log('TODO');
  }

}
