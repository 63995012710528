import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ContainerEvents, FileObject } from './types';
import { S3 } from 'aws-sdk';
import { S3Factory } from './s3-factory';

@Injectable({ providedIn: 'root' })
export class S3UploadService {

  private prefix: string;

  private uploadContainerEventSource = new Subject<ContainerEvents>(); // Observable string sources
  private fileUploadEventSource = new Subject<FileObject>(); // Observable string sources
  public uploadContrainerEvent$ = this.uploadContainerEventSource.asObservable(); // Observable string streams
  public fileUploadEvent$ = this.fileUploadEventSource.asObservable(); // Observable string streams

  // Upload status updates
  publishUploadContainerEvent(event: ContainerEvents) {
    this.uploadContainerEventSource.next(event);
  }

  publishFileUploadEvent(file: FileObject) {
    this.fileUploadEventSource.next(file);
  }

  private preparePutObjectRequest(file: File): S3.Types.PutObjectRequest {

    const obj = {
      Key: this.prefix + '/' + S3Factory.getDistFolder() + '/' + this.generateFileName(file),
      ACL: 'public-read',
      Bucket: S3Factory.getBucket(),
      Body: file,
      ContentType: file.type
    };
    return obj;
  }

  upload(file: File, progressCallback: (error: Error, progress: number, speed: number, data: any) => void) {
    const s3Upload = S3Factory.getS3().upload(this.preparePutObjectRequest(file));
    s3Upload.on('httpUploadProgress', this.handleS3UploadProgress(progressCallback));
    s3Upload.send(this.handleS3UploadComplete(progressCallback));
    return s3Upload;
  }

  public setPrefix(prefix: string) {
    console.log(prefix);
    this.prefix = prefix;
  }

  private handleS3UploadProgress(progressCallback: (error: Error, progress: number, speed: number, data) => void) {
    let uploadStartTime = new Date().getTime();
    let uploadedBytes = 0;
    return (progressEvent: S3.ManagedUpload.Progress) => {
      const currentTime = new Date().getTime();
      const timeElapsedInSeconds = (currentTime - uploadStartTime) / 1000;
      if (timeElapsedInSeconds > 0) {
        const speed = (progressEvent.loaded - uploadedBytes) / timeElapsedInSeconds;
        const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        progressCallback(undefined, progress, speed, null);
        uploadStartTime = currentTime;
        uploadedBytes = progressEvent.loaded;
      }
    };
  }

  private handleS3UploadComplete(progressCallback: (error: Error, progress: number, speed: number, data) => void) {
    return (error: Error, data: S3.ManagedUpload.SendData) => {
      if (error) {
        progressCallback(error, undefined, undefined, data);
      } else {
        progressCallback(error, 100, undefined, data);
      }
    };
  }

  getImageUrl(data: S3.ManagedUpload.SendData) {
    return S3Factory.getBaseUrl() + '/' + data.Key;
  }

  cancel(s3Upload: S3.ManagedUpload) {
    s3Upload.abort();
  }

  generateFileName(file: File) {
    let ext = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
    const a = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    const b = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    const c = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    const d = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    const filename =  a + b + '-' + c + d + '-' + a;
    if (ext === 'jpeg') {
        ext = 'jpg';
    }
    return filename + '.' + ext;
  }

}
