export const STATES = {
    SELECT_METHOD:  'SELECT_METHOD',
    DETECTING_LOCATION:  'DETECTING_LOCATION',
    LOCATION_FINDED:  'LOCATION_FINDED',
    LOCATION_FINDED_ERROR:  'LOCATION_FINDED_ERROR',
    BUILDING_ADDRESS:  'BUILDING_ADDRESS',
    BUILDING_ADDRESS_ERROR:  'BUILDING_ADDRESS_ERROR',
    VALIDATING_ADDRESS:  'VALIDATING_ADDRESS',
    FORM_ADDRESS:  'FORM_ADDRESS',
    ADDRESS_VALID: 'ADDRESS_VALID',
    ADDRESS_NO_VALID: 'ADDRESS_NO_VALID'
  };
