export const ROUTES_NAMES = {
  LOGIN: 'login',
  CREATE_ACCOUNT: 'crear-cuenta',
  LOGOUT: 'salir',
  HOME: 'home',
  MY_ACCOUNT: 'cuenta',
  PUBLISH: 'publicar',
  CONTACT: 'contacto',
  COMPANIES: {
    INDEX: 'empresas',
    CREATE: 'empresas/agregar'
  },
  CART: 'cart',
  CHECKOUT: 'checkout',
  PRODUCTS: 'productos',
  RESERVE: 'reservar',
  RENT: 'alquilar',
  MY_RENTALS: 'alquileres',
  MY_PUBLISH: 'publicaciones',
  MY_MESSAGES: 'mensajes',
  MY_NOTIFICATIONS: 'notificaciones',
  DASHBOARD: 'dashboard',
  INFORMATION: 'informacion',
  ADDRESSES: 'direcciones',
  RENTALS: 'alquileres',
  FORGOT_PASSWORD: 'recuperar-cuenta',
  CHANGE_PASSWORD: 'cambiar-contraseña',
  INVITE: 'invitar',
  BLOG: {
    INDEX: 'blog',
    TERM_AND_COND: 'blog/terminos-y-condiciones',
    PRIVACY_POLICY: 'blog/politica-de-privacidad',
    HOW_IT_WORK: 'blog/como-funciona/',
    HELP: 'blog/ayuda',
  },
  FAQ: 'faq'
};
