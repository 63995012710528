import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ROUTES_NAMES } from 'src/app/constants/route-names';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { PROFILES } from 'src/app/constants/profiles.constants';
import { User } from 'src/app/models';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnDestroy  {

  ROUTES_NAMES = ROUTES_NAMES;
  PROFILES = PROFILES;
  @Input() user: User;
  constructor(public authenticationService: AuthenticationService) {
                console.log(this.user);
  }

  logout(): void {
    console.log('logout');
    this.authenticationService.logout();
  }

  ngOnDestroy() {

  }

}
