import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilService {

  toQueryString(paramsObject: any): string {
    let result = '';
    if (paramsObject) {
      result = Object
        .keys(paramsObject)
        .map((key: string) => {
          if (Array.isArray(paramsObject[key])) {
            return paramsObject[key].map((innerKey: string) => `${encodeURIComponent(key)}=${encodeURIComponent(innerKey)}`)
              .join('&');
          } else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`;
          }
        })
        .join('&');
    }
    return result;
  }

}
