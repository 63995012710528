import { Component, OnInit, Input } from '@angular/core';
import { IRental } from 'src/app/models';

@Component({
  selector: 'app-card-rental',
  templateUrl: './card-rental.component.html',
  styleUrls: ['./card-rental.component.scss']
})
export class CardRentalComponent implements OnInit {

  @Input() title = 'Dirección';
  @Input() rental: IRental;
  constructor() { }

  ngOnInit() {
   console.log(this.rental);
  }

}
