import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { UtilService } from 'src/app/services/util.service';

@Injectable({ providedIn: 'root' })
export class GeoDataService {

  private API_URL = 'https://apis.datos.gob.ar/georef/api' ;

  constructor(public http: HttpClient,
              private utilsService: UtilService) {
  }

  public getProvincias(query?: any): Observable<any[]> {
    const queryParams: string = _.isEmpty(query) ? '' : '?' + this.utilsService.toQueryString(query);
    return this.http.get<Array<any>>(`${this.API_URL}/provincias${queryParams}`);
  }

  public getDepartamentos(query?: any): Observable<any[]> {
    const queryParams: string = _.isEmpty(query) ? '' : '?' + this.utilsService.toQueryString(query);
    return this.http.get<Array<any>>(`${this.API_URL}/departamentos${queryParams}`);
    // https://apis.datos.gob.ar/georef/api/municipios?provincia=50&campos=id,nombre&max=100
  }

  public getLocalidades(query?: any): Observable<any[]> {
    const queryParams: string = _.isEmpty(query) ? '' : '?' + this.utilsService.toQueryString(query);
    return this.http.get<Array<any>>(`${this.API_URL}/localidades${queryParams}`);
    // localidades de mendoza
    // https://apis.datos.gob.ar/georef/api/localidades?provincia=50&formato=json&max=5000
    // localidades de mendoza, las heras
    // https://apis.datos.gob.ar/georef/api/localidades?departamento=50049&formato=json&max=5000
  }

  public getMunicipios(query?: any): Observable<any[]> {
    const queryParams: string = _.isEmpty(query) ? '' : '?' + this.utilsService.toQueryString(query);
    return this.http.get<Array<any>>(`${this.API_URL}/municipios${queryParams}`);
    // https://apis.datos.gob.ar/georef/api/municipios?provincia=50&campos=id,nombre&max=100
  }
}

