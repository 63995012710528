import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IAddress } from '../models';

@Injectable({ providedIn: 'root' })
export class AddressService {

  constructor(public http: HttpClient) {
  }

  public toString(address: IAddress) {
    console.log(address);
    return address.addressLine1 + ' ' + address.addressLine2 + ' ' + address.city + ' ' + address.province;
  }

}
