import { Pipe, PipeTransform } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';

@Pipe({
  name: 'imgSize'
})
export class ImgSizePipe implements PipeTransform {

  constructor(private imageService: ImagesService){}

  transform(imgurl: string, size: string): string {
    return this.imageService.getUrl(imgurl , size);
  }
}
