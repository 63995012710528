import { S3, config } from 'aws-sdk';
import { environment } from 'src/environments/environment';

export class S3Factory {

    private static s3Conector = {};
    private static awsConfig;

    static loadAwsConfing() {
        this.awsConfig = {
            credentials: {
                user: environment.AWS_USER,
                accessKeyId: environment.AWS_ACCESS_KEY,
                secretAccessKey: environment.AWS_ACCESS_SECRET,
                region: environment.AWS_REGION
            },
            s3 : {
                path: 'https://s3.amazonaws.com/',
                bucket: environment.AWS_BUCKET,
                dstFolder: 'original',
                url: environment.AWS_CLOUDFRONT,
            }
        };
    }

    static getS3() {
        if (!this.awsConfig) {
            this.loadAwsConfing();
        }
        config.update({
            region: this.awsConfig.credentials.region,
            accessKeyId: this.awsConfig.credentials.accessKeyId,
            secretAccessKey: this.awsConfig.credentials.secretAccessKey
        });
        S3Factory.s3Conector = new S3();
        return S3Factory.s3Conector as S3;
    }

    static getDistFolder(): string {
        return this.awsConfig.s3.dstFolder;
    }

    static getBucket(): string {
        return this.awsConfig.s3.bucket;
    }

    static getBaseUrl(): string {
      return this.awsConfig.s3.url;
    }
}
