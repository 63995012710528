export const API = {
  SEARCH: '/busquedas',
  USERS: '/users',
  PROFILES: '/profiles',
  CATEGORIES: '/categories',
  PRODUCTS: '/products',
  AUTHENTICATE: '/authenticate',
  UPLOAD: '/upload',
  THREADS: '/threads',
  DELIVEY_OPTIONS: '/delivery-options',
  RESTORE_OPTIONS: '/restore-options',
  CONTACT: '/contact',
  INVITATION: '/invitation',
  VERIFICATION: '/verification',
  COMPANIES: '/companies',
  CLAIM_COMPANIES: '/claim-companies',
  RENT: '/rentals',
  ADMIN: '/admin',
  PLANS: '/plans'
};
