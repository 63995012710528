import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';

import { toSlug } from 'src/app/shared/util';
import { STATES } from './index';
import { IMG_SIZE } from 'src/app/constants/images-size';
import { FileObject, ContainerEvents, FileObjectStatus } from 'src/app/services/types';
import { ImagesService } from 'src/app/services/images.service';
import { S3UploadService } from 'src/app/services/s3-upload.service';

@Component({
  selector: 'app-upload-image-modal',
  templateUrl: './upload-image-modal.component.html',
  styleUrls: ['./upload-image-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadImageModalComponent implements OnInit, OnDestroy {

  modalTitle: string;
  fileObject: FileObject;
  FileObjectStatus = FileObjectStatus;
  progress = 0;
  speed = 0;
  uploadError: string;
  containerEventSubscription: Subscription;
  uploadHandle: any;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  viewState = STATES.SELECT_FILE;
  STATES = STATES;

  constructor(public formBuilder: FormBuilder,
              private s3UploadService: S3UploadService,
              private imageService: ImagesService,
              public dialogRef: MatDialogRef<UploadImageModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalTitle = data.title ||  '';
    this.s3UploadService.setPrefix(toSlug(data.prefixPath));
    this.containerEventSubscription = s3UploadService.uploadContrainerEvent$.subscribe(
      containerEvent => this.handleContainerEvent(containerEvent)
    );
  }

  ngOnInit() {
    this.viewState = STATES.SELECT_FILE;
  }

  openSelectImage($event) {
    $event.preventDefault();
    const inputElement: HTMLElement = this.fileInput.nativeElement as HTMLElement;
    inputElement.click();
  }

  private handleContainerEvent(containerEvent: ContainerEvents) {
    switch (containerEvent) {
      case ContainerEvents.Upload:
        return this.fileObject.status === FileObjectStatus.NotStarted && this.upload();
      case ContainerEvents.Cancel:
        return this.fileObject.status === FileObjectStatus.Uploading && this.cancel();
      case ContainerEvents.Delete:
        return this.deleteImage();
      default:
        break;
    }
  }

  handleS3UploadProgress() {
    return (error: Error, progress: number, speed: number, data: any) => {
      if (error) {
        this.progress = 0;
        this.speed = 0;
        this.uploadError = error.message;
        this.fileObject.status = FileObjectStatus.Failed;
      } else {
        this.progress = progress || this.progress;
        this.speed = speed || this.speed;
        if (this.progress === 100 && data != null) {
          this.fileObject.image = this.s3UploadService.getImageUrl(data);
          this.fileObject.status = FileObjectStatus.Uploaded;
          this.viewState = STATES.PREVIEW_IMAGE;
        }
      }
    };
  }

  deleteImage() {
    console.log('TODO// deleteImage');
  }

  cancel() {
    if (this.fileObject.status === FileObjectStatus.Uploading) {
      this.fileObject.status = FileObjectStatus.Canceled;
      this.s3UploadService.cancel(this.uploadHandle);
    }
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files.length) {
      this.fileObject = new FileObject(fileInput.target.files[0]);
    }
    fileInput.target.value = null;
    this.upload();
  }

  upload() {
    this.viewState = STATES.UPLOADING_FILE;
    this.fileObject.status = FileObjectStatus.Uploading;
    this.uploadError = undefined;
    this.progress = 0;
    this.uploadHandle = this.s3UploadService.upload(this.fileObject.file, this.handleS3UploadProgress());
  }

  confirmar($event) {
    $event.preventDefault();
    this.dialogRef.close(this.fileObject.image);
  }

  public close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.containerEventSubscription.unsubscribe();
  }
}
