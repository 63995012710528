import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-json',
  templateUrl: './card-json.component.html',
  styleUrls: ['./card-json.component.scss']
})
export class CardJsonComponent {

  @Input() toJson;
  @Input() expanded = false;
  constructor() { }

}
