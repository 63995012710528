import { Component, OnInit, Input } from '@angular/core';
import { ICompany } from 'src/app/models';

@Component({
  selector: 'app-card-company',
  templateUrl: './card-company.component.html',
  styleUrls: ['./card-company.component.scss']
})
export class CardCompanyComponent implements OnInit {

  @Input() title = 'Empresa';
  @Input() company: ICompany;

  constructor() { }

  ngOnInit() {
  }

}
