import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { } from 'googlemaps';
import { IAddress } from '../models/address';
import { MapsAPILoader } from '@agm/core';
import { BROWSER_GEOLOCATION } from 'src/app/constants';
declare var google: any;
import PlaceResult = google.maps.places.PlaceResult;

@Injectable({ providedIn: 'root' })
export class GeoLocationService {

  private geocoder: google.maps.Geocoder;
  BROWSER_GEOLOCATION = BROWSER_GEOLOCATION;

  constructor(private mapApiLoader: MapsAPILoader) {
    this.mapApiLoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder();
    });
  }

  public getUserLocation(geoLocationOptions?: any): Observable<any> {
    // geoLocationOptions = geoLocationOptions || { timeout: 0000 };

        return Observable.create(observer => {
          if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                observer.next(position);
                observer.complete();
              },
              (error) => {
                console.log(error);
                switch (error.code) {
                  case 1:
                    observer.error(BROWSER_GEOLOCATION.ERRORS.PERMISSION_DENIED);
                    break;
                  case 2:
                    observer.error(BROWSER_GEOLOCATION.ERRORS.POSITION_UNAVAILABLE);
                    break;
                  case 3:
                    observer.error(BROWSER_GEOLOCATION.ERRORS.TIMEOUT);
                    break;
                }
              });
        } else {
              observer.error(BROWSER_GEOLOCATION.ERRORS.UNSUPPORTED_BROWSER);
        }
      });
  }

  public locationToAddress(latitude: any , longitude: any ): Observable<IAddress> {
        const location = new google.maps.LatLng(latitude, longitude);
        return Observable.create(observer => {
          this.geocoder.geocode({ location: location }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const address: IAddress = this.convertGeocodeResultToAddress(results);
              address.lat = latitude;
              address.lng = longitude;
              observer.next(address);
              observer.complete();
            } else {
              observer.error(status);
            }
          });
      });
  }

  convertGeocodeResultToAddress(results: google.maps.GeocoderResult[]): IAddress {
    console.log(results);
    let address: any = {};
    for (let i = 0; i < results.length; i++) {
      console.log(results[i].types[0]);
      switch (results[i].types[0]) {
        case 'administrative_area_level_1':
          address.province = results[i].address_components[0].short_name;
          break;
        case 'administrative_area_level_2':
          address.city = results[i].address_components[0].short_name;
          break;
        case 'locality':
          address.locality = results[i].address_components[0].short_name;
          break;
        case 'neighborhood':
          address.locality = results[i].address_components[0].short_name;
          break;
        default:
          break;
      }
    }
    return address;
  }

  public getPostalCode(placeResult: PlaceResult): string {
    let postalCode: string;
    for (let i = 0; i < placeResult.address_components.length; i++) {
      if (placeResult.address_components[i].types[0] === 'postal_code') {
        // console.log(placeResult.address_components[i]);
          postalCode = placeResult.address_components[i].short_name;
          postalCode = postalCode.substr(1); // eliminar la letra del codigo postal
      }
    }
    return postalCode;
  }

  public cleanGooglePostalCode(postal_code: any): string {
    return postal_code.substr(0);
  }

  private getAddressType(results: google.maps.GeocoderResult[], i: number): string {
    return results[i].types[0];
  }

  public findAddressByCoordinates(query: google.maps.GeocoderRequest): Observable<google.maps.GeocoderResult[]> {
    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }
     return Observable.create(observer => {
       this.geocoder.geocode(query, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
              observer.next(results);
              observer.complete();
            } else {
              console.log('Geocoder failed due to: ' + status);
              observer.error(status);
            }
        });
     });
  }

  verificarAddress(address: IAddress): Observable<Array<IAddress>> {
    let stringAddress = `${address.addressLine1}, ${address.city}, ${address.province}, Argentina`;
    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }
    return Observable.create(observer => {
      this.geocoder.geocode({
            'address': stringAddress
      }, (results, status) => {
        console.log('1. Verificando direccion: ' + stringAddress);
        console.log('2. Resultados: ' + results.length);
        console.log('3. Status: ' + status);
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          let addresses: Array<IAddress> = this.getAddressesFromGeocoderResult(results);
          observer.next(addresses);
          observer.complete();
        } else {
           // status : portal/src/app/constants/google.maps.geocoder.constants.ts
           observer.error(status);
        }
      });
    });
  }

  getZipCode(address: IAddress): Observable<{cp: string,
                                             results: Array<IAddress>,
                                             total: number
                          }> {
    console.log('*** Buscando Codigo Postal *****');
    let stringAddress = `${address.addressLine1}, ${address.locality} ${address.city}, ${address.province}, Argentina`;
    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }
    return Observable.create(observer => {
      this.geocoder.geocode({
            'address': stringAddress
      }, (results, status) => {
        console.log('1. Verificando direccion: ' + stringAddress);
        console.log('2. Resultados: ' + results.length);
        console.log('3. Status: ' + status);
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          let addressResults: Array<IAddress> = this.getAddressesFromGeocoderResult(results);
          for(let addressItem of addressResults) {
            console.log(addressItem);
              this.geocoder.geocode({
                'location': {
                      lat: addressItem.lat,
                      lng: addressItem.lng
                    }
                }, (results2, status2) => {
                    if (status2 === google.maps.GeocoderStatus.OK) {
                      let addressResults2: Array<IAddress> = this.getAddressesFromGeocoderResult(results2);
                      console.log(addressResults2);
                      let arrayCp = [];
                      for(let addressItem2 of addressResults2) {
                          arrayCp.push(addressItem2.postalCode);
                      }
                        observer.next({
                          cp: arrayCp.filter(item => item !== undefined),
                          results: addressResults,
                          total: results.length
                        });
                        observer.complete();
                      } else {
                        console.log('Geocoder failed due to: ' + status2);
                        observer.error(status2);
                      }
                  });
          }

        } else {
           // status : portal/src/app/constants/google.maps.geocoder.constants.ts
           observer.error(status);
        }
      });
    });
  }

  public validarDireccion(userAddress): Observable<google.maps.GeocoderResult[]> {
    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }
    return Observable.create(observer => {
      this.geocoder.geocode({
            'address': userAddress
      }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          observer.next(results);
          observer.complete();
        } else {
           // status : portal/src/app/constants/google.maps.geocoder.constants.ts
           observer.error(status);
        }
      });
    });
  }

  public getAddressesFromGeocoderResult(results: google.maps.GeocoderResult[]): Array<IAddress> {
    let addresses: Array<IAddress> = [];
    for (let result of results) {
      let address = this.getAddressFromGeocoderResult(result);
      addresses.push(address);
    }
    return addresses;
  }

  public getAddressFromGeocoderResult(result: google.maps.GeocoderResult): IAddress {
    let address: IAddress  = {
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng()
    };

    for (let element of result.address_components) {
      if (!element['types']) {
        continue;
      }
      if (element['types'].indexOf('street_number') > -1) {
        address.addressLine1 = element['long_name'] ;
        continue;
      }
      if (element['types'].indexOf('route') > -1) {
        address.addressLine1 = element['long_name'] + ' ' + address.addressLine1;
        continue;
      }
      if (element['types'].indexOf('administrative_area_level_1') > -1) {
        address.province =  element['long_name'];
        continue;
      }
      if (element['types'].indexOf('administrative_area_level_2') > -1) {
        address.city =  element['long_name'];
        continue;
      }
      if (element['types'].indexOf('neighborhood') > -1) {
        address.locality =  element['long_name'];
        continue;
      }
       if (element['types'].indexOf('locality') > -1) {
        address.locality =  element['long_name'];
        continue;
      }
      if (element['types'].indexOf('country') > -1) {
        address.country = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('postal_code') > -1) {
        address.postalCode = element['short_name'];
        address.postalCode = address.postalCode.substr(1);
        continue;
      }
    }
    return address;
  }

  distanciaEntreDosDirecciones(query1: google.maps.LatLngLiteral, query2: google.maps.LatLngLiteral) {
    // example query1 = { lat: 50.995093; lng: -16.417091 };
    let x1 = new google.maps.LatLng(50.995093, -16.417091);
    let x2 = new google.maps.LatLng(50.997698, -16.41788);
    let distancia = google.maps.geometry.spherical.computeDistanceBetween(x1, x2);
    console.log(distancia);
    return distancia;
  }

}
