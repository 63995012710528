import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { IMG_SIZE } from 'src/app/constants';

@Component({
  selector: 'app-select-image-modal',
  templateUrl: './select-image-modal.component.html',
  styleUrls: ['./select-image-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectImageModalComponent {

  modalTitle: string;
  IMG_SIZE = IMG_SIZE;
  images = [];
  currentImage = null;

  constructor(public dialogRef: MatDialogRef<SelectImageModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      this.modalTitle = data.title ||  '';
      this.currentImage  = data.current;
      this.images = data.images || [];
  }

  selectImage(imageUrl) {
    this.dialogRef.close(imageUrl);
  }

  isCurrent(image) {
    return (image === this.currentImage);
  }

  public close(event): void {
    this.dialogRef.close();
  }

}
