
import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { HttpError } from 'src/app/constants/http-errors.constants';
import { ROUTES_NAMES } from 'src/app/constants/route-names';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from '../services';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
              private authorizationService: AuthenticationService,
              private spinner: NgxSpinnerService,
              public router: Router,
              public snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const logFormat = 'background: red; color: white';
      return next.handle(req)
          .pipe(
              retry(1),
              catchError((error: HttpErrorResponse) => {
                  console.error(error);
                  if (error instanceof HttpErrorResponse) {
                    // console.log(error);
                    this.spinner.hide();
                    switch (error.status) {
                        case HttpError.BadRequest:
                            console.error('%c Bad Request 400', logFormat);
                            break;
                        case HttpError.Unauthorized:
                            if (error.error && error.error.type && error.error.type === 'not-verified') {
                                console.log('not-verified');
                                this.router.navigate([ROUTES_NAMES.LOGIN]);
                            }
                            if (error.error && error.error.type && error.error.type === 'user-no-exist') {
                                console.log('user-no-exist');
                                this.router.navigate([ROUTES_NAMES.LOGIN]);
                            }
                            if (error.error && error.error.type && error.error.type === 'activate-account-token-expired') {
                                console.log('activate-account-token-expired');
                                this.router.navigate([ROUTES_NAMES.LOGIN]);
                            }

                            if (error.error && error.error.name && error.error.name === 'TokenExpiredError' ) {
                                console.log('error token expired');
                                this.authorizationService.logout();
                                this.router.navigate([ROUTES_NAMES.LOGIN]);
                            }
                            // checkToken()
                            this.router.navigate([ROUTES_NAMES.LOGIN]);
                            this.authorizationService.logout();
                            break;

                        case HttpError.NotFound:
                            console.error('%c Not Found 404', logFormat);
                            console.log({
                                message: error.error && error.error.message ? error.error.message :
                                    error.statusText,
                                typeId: 'error',
                                isDismissable: true
                            });
                            this.snackBar.open('Upps ..ocurrio un error. ', '×', {
                                panelClass: ['error'],
                                verticalPosition: 'top',
                                duration: 3000
                            });
                            break;

                        case HttpError.TimeOut:
                            // Handled in AnalyticsExceptionHandler
                            console.error('%c TimeOut 408', logFormat);
                            this.router.navigate(['/']);
                            break;

                        case HttpError.Forbidden:
                            console.error('%c Forbidden 403', logFormat);
                            this.router.navigate(['/']);
                            break;

                        case HttpError.InternalServerError:
                            console.error('%c big bad 500', logFormat);
                            this.router.navigate(['/']);
                            break;

                        default :
                            const started = Date.now();
                            const elapsed = Date.now() - started;
                            const msg = `Request for ${req.urlWithParams} failed after ${elapsed} ms.`;
                            console.error('%c default msg: ' + msg, logFormat);
                            this.snackBar.open('Lo siento, ocurrio un error', '×', {
                                panelClass: ['error'],
                                verticalPosition: 'top',
                                duration: 3000
                            });
                            break;
                    }
                }
                  return throwError('errorMessage');
              })
          );
  }
}
