import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import GeocoderRequest = google.maps.GeocoderRequest;
import { Subscription } from 'rxjs';
import { MapsAPILoader } from '@agm/core';

import { IAddress } from 'src/app/models/index';
import { ADDRESS_TYPE } from 'src/app/constants/index';
import { GeoLocationService } from 'src/app/services/geo-location.service';
import { GeoDataService } from 'src/app/services/geo-data.service';
import { STATES } from './index';
import { GOOGLE_MAPS_GEOCODER } from 'src/app/constants/index';
import * as _ from 'lodash';
import { compareValues } from '../util';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddressModalComponent implements OnInit, OnDestroy {

  address: IAddress;
  detectedAddress: IAddress;
  direccionVerificada: IAddress;
  addressForm: FormGroup;
  viewState = STATES.DETECTING_LOCATION;
  GOOGLE_MAPS_GEOCODER = GOOGLE_MAPS_GEOCODER;
  STATES = STATES;
  formStep = 1;
  @ViewChild('addressLine2', { static: true } ) addressLine2: ElementRef;
  provSub: Subscription;
  provincias: Array<{id: number, nombre: string }>;
  cities: Array<{id: number, nombre: string }>;
  localities: Array<{id: number, nombre: string }>;

  constructor(public formBuilder: FormBuilder,
              private ref: ChangeDetectorRef,
              public dialogRef: MatDialogRef<AddressModalComponent>,
              public geoLocationService: GeoLocationService,
              public geoDataService: GeoDataService,
              private mapApiLoader: MapsAPILoader,
              @Inject(PLATFORM_ID) private platformId: object) {
                this.mapApiLoader.load();
              }

  ngOnInit() {
    this.viewState = STATES.SELECT_METHOD;
  }

  ingresarManualmente($event) {
    $event.preventDefault();
    const address: IAddress = {
      method: 'MANUAL',
      lat: null,
      lng: null
    };
    this.formStep = 1;
    this.buildForm(address);
    this.viewState = STATES.FORM_ADDRESS;
    this.loadProvincias();
  }

  detectarUbicacion($event) {
    $event.preventDefault();
    this.viewState = STATES.DETECTING_LOCATION;
    this.detectLocation();
  }

  async loadProvincias() {
    const result = await this.geoDataService.getProvincias().toPromise();
    this.provincias = _.get(result, 'provincias');
    this.provincias.sort(compareValues('nombre'));
  }

  async onProvinceChange(name) {
    const provincia = this.provincias.filter(item => item.nombre === name )[0];
    const query: any = {
      provincia : provincia.id,
      max: 100
    };
    const result = await this.geoDataService.getDepartamentos(query).toPromise();
    this.cities = _.get(result, 'departamentos');
    this.cities.sort(compareValues('nombre'));
    this.addressForm.controls['city'].setValue(null);
  }

  /*
  async onCityChange(name) {
    let city = this.cities.filter(item => item.nombre === name )[0];
    let query: any = {
      departamento : city.id,
      max: 1000
    };
    let result = await this.geoDataService.getLocalidades(query).toPromise();
    this.localities = _.get(result, 'localidades');
    this.localities.sort(compareValues('nombre'));
    this.addressForm.controls['locality'].setValue(null);
  }
  */

  enableStep1($event) {
    $event.preventDefault();
    this.formStep = 1;
  }

  enableStep2($event) {
    $event.preventDefault();
    const province = this.addressForm.get('province').valid;
    const city = this.addressForm.get('city').valid;
    const postalCode = this.addressForm.get('postalCode').valid;
    if (province && city && postalCode) {
      this.formStep = 2;
    }
  }

  async detectLocation() {
    const address: IAddress = { lat: null, lng: null };
    try {
        const position  = await this.geoLocationService.getUserLocation({ enableHighAccuracy: true }).toPromise();
        this.viewState = STATES.LOCATION_FINDED;
        const geocoderRequest: GeocoderRequest = {
        location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
        };
        const results: google.maps.GeocoderResult[] = await this.geoLocationService.findAddressByCoordinates(geocoderRequest).toPromise();
        this.detectedAddress = this.geoLocationService.getAddressesFromGeocoderResult(results)[0];
        this.detectedAddress.method = 'DETECT_LOCATION';
        this.buildForm(this.detectedAddress);
    } catch (error) {
        this.viewState = STATES.LOCATION_FINDED_ERROR;
    }
  }

  buildForm(address: IAddress ) {
    this.addressForm = this.formBuilder.group({
      addressLine1: [address.addressLine1, Validators.required],
      addressLine2: [''],
      locality: [address.locality],
      postalCode: [address.postalCode, Validators.required],
      province: [address.province, Validators.required],
      city: [address.city, Validators.required],
      lat: [address.lat],
      lng: [address.lng],
      type: [ADDRESS_TYPE.MAIN],
      method: [address.method]
    });
    this.viewState = STATES.FORM_ADDRESS;
  }

  intentarDeNuevo($event) {
    $event.preventDefault();
    this.viewState = STATES.FORM_ADDRESS;
  }

  async submitDetectedLoc() {
    try {
      const address: IAddress = this.addressForm.value;
      if (this.addressForm.valid) {
        const results: Array<IAddress> = await this.geoLocationService.verificarAddress(address).toPromise();
        if (results.length === 1 && this.isEqualCoordinates(address, results[0])) {
          this.dialogRef.close(address);
          return;
        }
        if (results.length === 1 && !this.isEqualCoordinates(address, results[0])) {
          // Coordendas distintas: El usuario modifico la direccion detectada
          address.lat = results[0].lat;
          address.lng = results[0].lng;
          this.dialogRef.close(address);
          return;
        }
          // console.log('El usuario modifico la direccion y ahora no es valida.');
        this.viewState = STATES.ADDRESS_NO_VALID;
      }
    } catch (err) {
      console.log(err);
      if (err === GOOGLE_MAPS_GEOCODER.STATUS.ZERO_RESULTS) {
        this.viewState = STATES.ADDRESS_NO_VALID;
      }
    }
  }

  async submitManualAddress() {
    try {
          const address: IAddress = this.addressForm.value;
          if (this.addressForm.valid) {
            // validar si la direccion es geolocalizable
            // obtener las coordenadas Lat y Lng
            this.viewState = STATES.ADDRESS_VALID;
            this.dialogRef.close(address);
            return;
          }
          return;
    } catch (err) {
      if (err === GOOGLE_MAPS_GEOCODER.STATUS.ZERO_RESULTS) {
        this.viewState = STATES.ADDRESS_NO_VALID;
      }
    }
  }

  isEqualCoordinates(address1: IAddress, address2: IAddress) {
    return (address1.lat === address2.lat && address1.lng === address2.lng);
  }

  public confirmar($event) {
    const address: IAddress = this.addressForm.value;
    $event.preventDefault();
    this.dialogRef.close(address);
  }

  public close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {

  }

}
