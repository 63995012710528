export const BROWSER_GEOLOCATION = {
  ERRORS : {
    UNSUPPORTED_BROWSER: 'UNSUPPORTED_BROWSER',
    PERMISSION_DENIED: 'PERMISSION_DENIED',
    POSITION_UNAVAILABLE: 'POSITION_UNAVAILABLE',
    TIMEOUT: 'TIMEOUT'
  },
  ERRORS_DESC : {
    UNSUPPORTED_BROWSER: 'Browser does not support location services',
    PERMISSION_DENIED: 'You have rejected access to your location',
    POSITION_UNAVAILABLE: 'Unable to determine your location',
    TIMEOUT: 'Service timeout has been reached'
  }
};
