export const RESTORE_OPTIONS_CONSTANTS = {
  STATUS: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled'
  },
  CODES: {
    IT_IS_RETURNED_AT_MY_LOCATION : 'IT_IS_RETURNED_AT_MY_LOCATION',
    I_PICK_UP_YOUR_ADDRESS : 'I_PICK_UP_YOUR_ADDRESS',
    AGREED_IN_ONE_PLACE: 'AGREED_IN_ONE_PLACE'
  }
};
