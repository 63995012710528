import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { IRental } from 'src/app/models';
import { RENTALS_CONSTANTS } from 'src/app/constants/rentals.constants';

@Component({
  selector: 'app-card-tracking',
  templateUrl: './card-tracking.component.html',
  styleUrls: ['./card-tracking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardTrackingComponent implements OnInit {

  @Input('title') title: string = 'Seguimiento';
  @Input('rental') rental: IRental;
  RENTALS_CONSTANTS = RENTALS_CONSTANTS;
  flags = [true, false, false ];
  flagsName = ['Enviada', 'Recibida', 'Aceptada'];

  ngOnInit() {
    switch (this.rental.status) {
      case RENTALS_CONSTANTS.STATUS.SENT:
          this.flags = [true, false, false ];
          break;
      case RENTALS_CONSTANTS.STATUS.RECEIVED:
          this.flags = [true, true, false ];
          break;
      case RENTALS_CONSTANTS.STATUS.COMMIT_FOR_OWNER:
          this.flags = [true, true, true ];
          this.flagsName[2] = RENTALS_CONSTANTS.STATUS_LABEL[this.rental.status];
          break;
      case RENTALS_CONSTANTS.STATUS.COMMIT_FOR_RENTER:
          this.flags = [true, true, true ];
          this.flagsName[2] = RENTALS_CONSTANTS.STATUS_LABEL[this.rental.status];
          break;
      case RENTALS_CONSTANTS.STATUS.CANCEL_FOR_OWNER:
          this.flags = [true, true, true ];
          this.flagsName[2] = RENTALS_CONSTANTS.STATUS_LABEL[this.rental.status];
          break;
      case RENTALS_CONSTANTS.STATUS.CANCEL_FOR_RENTER:
          this.flags = [true, true, true ];
          this.flagsName[2] = RENTALS_CONSTANTS.STATUS_LABEL[this.rental.status];
          break;
      case RENTALS_CONSTANTS.STATUS.EXPIRED:
          this.flags = [true, true, true ];
          this.flagsName[2] = RENTALS_CONSTANTS.STATUS_LABEL[this.rental.status];
          break;
      case RENTALS_CONSTANTS.STATUS.CLOSED:
          this.flags = [true, true, true ];
          this.flagsName[2] = RENTALS_CONSTANTS.STATUS_LABEL[this.rental.status];
          break;
      default:
        break;
    }
  }

}
