import { Component, OnInit, Input } from '@angular/core';
import { IRental } from 'src/app/models';

@Component({
  selector: 'app-card-rental-messages',
  templateUrl: './card-rental-messages.component.html',
  styleUrls: ['./card-rental-messages.component.scss']
})
export class CardRentalMessagesComponent implements OnInit {

  @Input() rental: IRental;
  constructor() { }

  ngOnInit() {
  }

}
