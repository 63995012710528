import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './core';

export const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'api', loadChildren: () => import('./pages/api/api.module').then(m => m.APIModule), data: { breadcrumb: 'API' } },
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Compare' } },
            { path: 'wishlist', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: 'Wishlist' } },
            { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Cart' } },
            { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact' } },
            { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Sign In ' } },
            { path: 'brands', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'Brands' } },
            { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'Productos' } },
            { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Usuarios' } },
            { path: 'threads', loadChildren: () => import('./pages/threads/threads.module').then(m => m.ThreadsModule), data: { breadcrumb: 'Mensajes entre usuarios' } },
            { path: 'companies', loadChildren: () => import('./pages/companies/companies.module').then(m => m.CompaniesModule), data: { breadcrumb: 'Empresas' } },
            { path: 'rentals', loadChildren: () => import('./pages/rentals/rentals.module').then(m => m.RentalsModule), data: { breadcrumb: 'Alquileres' } },
            { path: 'busquedas', loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule), data: { breadcrumb: 'Busquedas' } },
            { path: 'plans', loadChildren: () => import('./pages/plans/plans.module').then(m => m.PlansModule), data: { breadcrumb: 'Planes' } }
        ]
    },
    { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
   preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
   initialNavigation: 'enabled'  // for one load page, without reload
   // useHash: true
});
